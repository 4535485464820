import React from 'react';
import styled from 'styled-components';

import scale from '@/styles/scale';

import Text from '@/atoms/Text';

const Container = styled.li`
  display: flex;
  border: 1px solid ${props => props.theme.color.base};
  border-width: 1px 0 0;
  padding: ${scale(1.25)} 0;
  color: ${props => props.theme.color.gray};
`;

export default props => (
  <Container {...props}>
    <Text>Your Shopping Bag is currently empty.</Text>
  </Container>
);
