import React from 'react';
import styled from 'styled-components';

import CartItem from './CartItem';
import EmptyMessage from './EmptyMessage';

const List = styled.ul`
  list-style: none;
`;

export default ({
  lineItems,
  handleQuantityChange,
  handleRemove,
  ...props
}) => (
  <List {...props}>
    {lineItems.length > 0 ? (
      lineItems.map(({ node }) => (
        <CartItem
          key={node.id}
          name={node.title}
          producer={node.variant.product.vendor}
          bottleSize={node.variant.product.productType}
          image={node.variant.image ? node.variant.image.transformedSrc : null}
          quantity={node.quantity}
          price={node.variant.price}
          setItemQuantity={newQuantity =>
            handleQuantityChange(node.id, newQuantity)
          }
          removeItem={() => handleRemove(node.id)}
        />
      ))
    ) : (
      <EmptyMessage />
    )}
  </List>
);
