import React from 'react';
import styled from 'styled-components';

import dict from '@/dict';

import { getPriceString } from '@/utils/pricing';

import media from '@/styles/media';
import scale from '@/styles/scale';
import { h5 } from '@/styles/typography';

import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import LazyImage from '@/components/LazyImage';
import QuantityInput from '@/components/QuantityInput';

const Product = styled.li`
  display: flex;
  align-items: flex-start;
  border: 1px solid ${props => props.theme.color.base};
  border-width: 1px 0 0;
  padding: ${scale(0.5)} 0;

  :last-child {
    ${media.md`
      border-width: 1px 0;
    `}
  }
`;

const Image = styled(LazyImage)`
  flex: 0 0 auto;
  flex-grow: false;
  margin-right: ${scale(1)};
  width: 102px;

  ${media.md`
    width: 117px;
  `}
`;

const Info = styled.div`
  padding: ${scale(0.75)} 0;
  width: 100%;
`;

const InfoHeader = styled.div`
  padding-bottom: ${scale(0.25)};

  ${media.md`
    padding-bottom: ${scale(0.5)};
  `}
`;

const InfoDetails = styled.dl`
  padding-bottom: ${scale(0.75)};

  ${media.md`
    padding-bottom: ${scale(1.25)};
  `}

  dt {
    display: inline-block;
    padding-right: ${scale(0.375)};
  }

  dd {
    display: inline-block;
    padding-right: ${scale(1)};

    ${media.md`
      padding-right: ${scale(1.5)};
    `}

    :last-child {
      padding: 0;
    }
  }
`;

const InfoPurchase = styled.div`
  display: flex;
  align-items: center;
`;

const Quantity = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  margin-right: ${scale(0.5)};
`;

const RemoveButton = styled.button`
  ${h5}
  margin-left: ${scale(1)};
  border: 0;
  height: ${scale(1.25)};
  color: ${props => props.theme.color.gray};
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
`;

export default ({
  id,
  name,
  producer,
  bottleSize,
  image,
  quantity,
  price,
  setItemQuantity,
  removeItem,
  ...props
}) => {
  const maxQuantity = quantity;
  const total = Number.parseFloat(parseFloat(price) * quantity).toFixed(2);

  const handleQuantityChange = event => {
    setItemQuantity(parseInt(event.target.value, 10));
  };

  return (
    <Product {...props}>
      <Image
        src={image}
        placeholder="product"
        alt={name}
        aspectRatio={1 / 1.38}
        isImgix={false}
      />

      <Info>
        <InfoHeader>
          <Heading h="productSmall" as="h3">
            {name}
          </Heading>

          <Text t={-1} as="h4">
            {producer}
          </Text>
        </InfoHeader>

        <InfoDetails>
          <Heading h={5} as="dt">
            Size
          </Heading>

          <Text t={-1} as="dd">
            {dict(bottleSize, 'bottle')}
          </Text>

          <Heading h={5} as="dt">
            Price
          </Heading>

          <Text t={-1} as="dd">
            {getPriceString(price)}
          </Text>
        </InfoDetails>

        <InfoPurchase>
          <Quantity>
            <QuantityInput
              max={maxQuantity}
              value={quantity}
              onChange={handleQuantityChange}
            />

            <RemoveButton onClick={removeItem}>Remove</RemoveButton>
          </Quantity>

          <Heading h={4} as="dd">
            {getPriceString(total)}
          </Heading>
        </InfoPurchase>
      </Info>
    </Product>
  );
};
