import React from 'react';
import styled from 'styled-components';
import { Query, Mutation } from 'react-apollo';

import CartContext from '@/context/CartContext';

import {
  GET_CHECKOUT,
  REMOVE_LINE_ITEM,
  UPDATE_LINE_ITEM,
  optimisticUpdateLineItem,
  optimisticRemoveLineItem,
} from '@/data/checkout';

import scale from '@/styles/scale';
import media from '@/styles/media';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import PageHeader from '@/components/PageHeader';
import CartTable from '@/components/CartTable';
import CartTotals from '@/components/CartTotals';
import Head from '@/components/Head';
import PaymentOptions from '@/components/PaymentOptions';

const Info = styled.div`
  display: none;
  margin-top: ${scale(5)};

  ${media.md`
    display: block;
  `}
`;

const InfoHeading = styled(Heading).attrs({ h: 5 })`
  margin-top: ${scale(3)};
  margin-bottom: ${scale(0.5)};
`;

const Cart = ({
  data,
  checkoutId,
  removeLineItem,
  updateLineItem,
  ...props
}) => {
  const { node } = data;

  // wether shopify has already calculated the shipping costs
  const hasShippingCosts = node.shippingLine !== null;
  const hasLineItems = node.lineItems.edges.length !== 0;
  const defaultShippingCosts = '6.00';
  let shippingTotal;
  let total;
  let totalTax;

  if (hasLineItems) {
    if (hasShippingCosts) {
      shippingTotal = node.shippingLine.price;
      // free shipping in DE over €100
    } else if (parseFloat(node.totalPrice) >= 100) {
      shippingTotal = '0.00';
    } else {
      shippingTotal = defaultShippingCosts;
    }

    // eslint-disable-next-line prefer-destructuring
    totalTax = node.totalTax;

    total = hasShippingCosts
      ? node.totalPrice
      : Number.parseFloat(
          parseFloat(shippingTotal) + parseFloat(node.totalPrice)
        ).toFixed(2);
  } else {
    shippingTotal = '0.00';
    totalTax = '0.00';
    total = '0.00';
  }

  const checkoutUrl = node.webUrl.replace(
    '24-7-wine-supply.myshopify.com',
    'checkout.skincontact.com'
  );

  const handleQuantityChange = (id, quantity) => {
    updateLineItem({
      variables: {
        checkoutId,
        lineItemId: id,
        lineItemQuantity: quantity,
      },
      optimisticResponse: optimisticUpdateLineItem(data, id, quantity),
    });
  };

  const handleRemove = id => {
    removeLineItem({
      variables: {
        checkoutId,
        lineItemId: id,
      },
      optimisticResponse: optimisticRemoveLineItem(data, id),
    });
  };

  return (
    <>
      <Head title="Shopping Bag" noindex />

      <Grid.Container as="section" {...props}>
        <PageHeader title="Shopping Bag" />

        <Grid>
          <Grid.Item w={[1, 1, 8 / 12, 7 / 12]}>
            <CartTable
              lineItems={node.lineItems.edges}
              handleQuantityChange={handleQuantityChange}
              handleRemove={handleRemove}
            />

            <Info>
              <InfoHeading>Shipping</InfoHeading>

              <Text t={-1}>
                We ship via UPS (insured) which usually takes 3–5 business days.
              </Text>

              <InfoHeading>Payment Options</InfoHeading>

              <PaymentOptions />
            </Info>
          </Grid.Item>

          <Grid.Item w={[1, 1, 4 / 12]} o={[0, 0, 0, 1 / 12]}>
            <CartTotals
              subtotal={node.subtotalPrice}
              totalShipping={shippingTotal}
              total={total}
              totalTax={totalTax}
              checkoutUrl={checkoutUrl}
              isReady={hasLineItems && node.ready}
            />
          </Grid.Item>
        </Grid>
      </Grid.Container>
    </>
  );
};

export default props => (
  <CartContext.Consumer>
    {({ checkoutId }) => (
      <Query
        query={GET_CHECKOUT}
        variables={{ checkoutId }}
        skip={!checkoutId}
        partialRefetch
      >
        {({ loading, data }) => {
          if (!loading && data) {
            return (
              <Mutation mutation={REMOVE_LINE_ITEM}>
                {removeLineItem => (
                  <Mutation mutation={UPDATE_LINE_ITEM}>
                    {updateLineItem => (
                      <Cart
                        data={data}
                        checkoutId={checkoutId}
                        removeLineItem={removeLineItem}
                        updateLineItem={updateLineItem}
                        {...props}
                      />
                    )}
                  </Mutation>
                )}
              </Mutation>
            );
          }
          return null;
        }}
      </Query>
    )}
  </CartContext.Consumer>
);
