import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { getPriceString } from '@/utils/pricing';

import scale from '@/styles/scale';
import media from '@/styles/media';

import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';
import Button from '@/atoms/Button';

const List = styled.ul`
  border: 1px solid ${props => props.theme.color.base};
  border-width: ${props => (props.totals ? '0' : '1px 0')};
  padding: ${scale(1.25)} 0;
`;

const ListItem = styled.li`
  display: flex;
  padding: ${scale(0.25)} 0;
  color: ${props =>
    props.secondary ? props.theme.color.gray : props.theme.color.base};
`;

const Content = styled(Text)`
  flex: 1 1 auto;
  padding-left: ${scale(0.5)};
  text-align: right;
`;

const ContentLarge = styled(Heading)`
  flex: 1 1 auto;
  padding-left: ${scale(0.5)};
  text-align: right;
`;

const Checkout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${scale(1)} 0;
  border: 1px solid ${props => props.theme.color.base};
  border-width: 0 0 1px;
`;

const LegalInfo = styled(Text)`
  margin-top: ${scale(2)};
  width: 100%;
  color: ${props => props.theme.color.gray};
  text-align: center;

  ${media.md`
    margin-top: ${scale(2.5)};
    text-align: left;
  `}

  a {
    color: inherit;
    border-bottom: 1px solid ${props => props.theme.color.gray};
  }
`;

// const Note = styled(Text)`
//   margin-top: ${scale(2)};
//   border: 1px solid ${props => props.theme.color.base};
//   border-radius: 5px;
//   padding: ${scale(0.25)} ${scale(0.5)};
// `;

export default ({
  subtotal = '0.00',
  totalShipping = '0.00',
  total = '0.00',
  totalTax = '0.00',
  checkoutUrl = null,
  isReady = false,
}) => (
  <>
    <List>
      <ListItem>
        <Text as="h5">Subtotal</Text>
        <Content>{getPriceString(subtotal)}</Content>
      </ListItem>

      <ListItem>
        <Text as="h5">Shipping</Text>
        <Content>
          {totalShipping === '0.00' ? 'free' : getPriceString(totalShipping)}
        </Content>
      </ListItem>
    </List>

    <List totals>
      <ListItem>
        <Heading h={3} as="h5">
          Total
        </Heading>

        <ContentLarge h={3} as="p">
          {getPriceString(total)}
        </ContentLarge>
      </ListItem>

      <ListItem secondary>
        <Text t={-1} as="h5">
          incl. VAT
        </Text>
        <Content t={-1}>{getPriceString(totalTax)}</Content>
      </ListItem>
    </List>

    <Checkout>
      <Button href={checkoutUrl} disabled>
        Go to Checkout
      </Button>

      <Text style={{ marginTop: '16px' }}>
        Our shop is currently closed. Sorry for the inconvenience.
      </Text>

      <LegalInfo t={-2}>
        Orders can be returned within 14 days from the date of delivery. <br />
        Read our <Link to="/terms-conditions/">Terms/AGB</Link> and{' '}
        <Link to="/refunds/">Refunds/Widerrufsrecht</Link>.
      </LegalInfo>
    </Checkout>
  </>
);
